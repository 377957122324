<template>
    <div class="member">
        <div v-if="!studyShow">
        <div class="operList">

            <el-select v-model="my_team.branch" @change="officeChange" placeholder="一级部门" style="width:130px;">
                <el-option
                v-for="item in organOffices"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            <el-select :disabled="offices.length < 1" @change="officeChange1" style="width:130px;margin-left:30px;margin-right:10px;" v-model="my_team.offices" placeholder="二级部门">
                <el-option
                v-for="item in offices"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            <el-date-picker
            style=" margin-left:20px;"
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd "
            value-format="yyyy-MM-dd">
            </el-date-picker>
            <button @click="dataReset">重置</button>
            <button @click="searchData">搜索</button>
            <button @click="exportExcel">导出EXCLE</button>
        </div>
        <temp-data v-if="showTempData"></temp-data>
        <div v-else>
        <el-table
            :data="teamOrg.data[page-1]"
            :header-cell-style="{background:'#F9FAFC',fontWeight:'normal',textAlign:'center',fontSize:'16px',color:'#333'}"
            :cell-style="{textAlign:'center',fontSize:'14px',color:'#333'}"
            border
            style="width: 915px;margin-top:20px;margin-left:20px;">
            <el-table-column
            label="排名"
            prop="ranking"
            width="180">
            </el-table-column>
            <el-table-column
            prop="name"
            label="头像"
            width="180">
            <template slot-scope="scope">
                <img :src="scope.row.portrait" style="width:60px;height:60px;border-radius:100px;" alt="">
            </template>
            </el-table-column>
            <el-table-column
            prop="name"
            label="姓名">
			<template slot-scope="scope">
			    <span style="font-size:14px;">{{scope.row.real_name ? scope.row.real_name : scope.row.name}}</span>
			</template>
            </el-table-column>
            <el-table-column
            prop="branch_off"
            label="部门科室">
            </el-table-column>
            <el-table-column
            prop="ctime"
            label="加入时间">
            </el-table-column>
            <el-table-column
            prop="address"
            label="积分数量">
            <template slot-scope="scope">
                <span style="font-size:14px;color:#47D7E3;">{{scope.row.point}}积分</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="address"
            label="操作">
            <template slot-scope="scope">
                <span style="font-size:14px;color:#47D7E3;cursor: pointer;" @click="readInfo(scope.row)">查看</span>
            </template>
            </el-table-column>
        </el-table>
        <el-pagination
        style="margin-top:37px;"
        background
        layout="prev, pager, next"
        :total="teamOrg.count"
        :page-size="10"
        @current-change="currentChange">
        </el-pagination>
        </div>
        </div>
        <div class="redStudy" v-else>
            <div class="flex spacebetween">
            <div class="personInfo flex alignCenter">
                <img :src="catchData.portrait" style="width:46px;height:46px;border-radius: 50px;overflost:hidden;" alt="">
                <div style="height:100%;margin-left:18px;align-items: flex-start;" class="flexColumn flex spacebetween">
                    <span class="c33 fontW" style="font-size:15px;">{{catchData.name}}</span>
                    <span class="c33" style="font-size:15px;">{{catchData.point}}</span>
                </div>
                <div class="xunz" :style="{background:`url(${catchData.icon})`}">
                    {{catchData.medal_name}}
                </div>
            </div>
            <span style="padding-right:30px;color:#47D7E3;" @click="studyShow = false" class="fontW cursorP">返回</span>
            </div>
            <table class="" cellspacing="0">
                <tr style="height:55px;background: #FAFAFA;">
                    <th style="width: 500px;">课程名称</th>
                    <th>学习进度</th>
                </tr>
                <tr v-for="(im,ix) in studyData[page-1]" :key="ix">
                    <td>{{im.c_title}}</td>
                    <td>{{im.rate == '0%' ? '未开始' : im.rate}}</td>
                </tr>
            </table>
            <el-pagination
            style="margin-top:37px;"
            background
            layout="prev, pager, next"
            :total="studyDataCount"
            :page-size="10"
            @current-change="currentChangeStudy">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { my_teamOrg, member_look_log, organ_offices } from '@/utils/Api/userList'
import { frame_list } from '@/utils/Api/orgList'
import tempData from '@/components/tempData'
export default {
    name:'member',
    components:{tempData},
    data(){
        return{
            my_team:{},
            value1:'',
            teamOrg:{
                count:0,
                data:[]
            },
            page:1,
            showTempData: false,
            catchData:{},               // 点击查看时保存被查看人信息
            page1:1,                    // 学习记录的分页
            studyData:[],
            studyDataCount:0,           // 查看学习记录分页
            studyShow: false,
			organOffices:[],
			offices:[]
        }
    },
    created(){
        this.initialBrfore()
		frame_list().then(res => {
			this.organOffices = res
		})
    },
    methods:{
		officeChange1(){
			this.$forceUpdate()
		},
		officeChange(){
			frame_list({pid: this.my_team.branch}).then(res => {
				this.offices = res
			})
		},
        dataReset(){
            this.my_team.branch = ''
            this.my_team.offices = ''
            this.my_team.start_time = ''
            this.my_team.end_time = ''
            this.value1 = ''
            this.initialBrfore()
        },
        async exportExcel(){
            // console.log(`http://nmep.com.cn/api.php/api/Organ/export_team?token=${this.$store.getters.token}&branch=${this.my_team.branch ? this.my_team.branch : ''}&offices=${this.my_team.offices ? this.my_team.offices : ''}&start_time=${this.my_team.start_time ? this.my_team.start_time : ''}&end_time=${this.my_team.end_time ? this.my_team.end_time : ''}`)
            window.open(`http://nmep.com.cn/api.php/api/Organ/export_team?token=${this.$store.getters.token}&branch=${this.my_team.branch ? this.my_team.branch : ''}&offices=${this.my_team.offices ? this.my_team.offices : ''}&start_time=${this.my_team.start_time ? this.my_team.start_time : ''}&end_time=${this.my_team.end_time ? this.my_team.end_time : ''}`)
        },
        async readInfo(val){
            console.log(val)
            this.page1 = 1
            this.catchData = val
            this.getStudyPress()
        },
        async getStudyPress(val){
            let params = {
                page: this.page1,
                limit: 10,
                user_id: this.catchData.id
            }
            let cbk = await member_look_log(params)
            console.log(cbk)
            if(cbk && cbk.res && cbk.res.length > 0){
                this.studyDataCount = cbk.count
                this.studyData[this.page-1] = cbk.res
                this.studyShow = true
                console.log(this.studyData)
            }else{
				this.$message.error('暂无数据！')
			}
        },
        initialBrfore(){
            let params = {
                page: this.page,
                limit: 10,
                ...this.my_team
            }
            this.initial(params)
        },
        async initial(params){
            let teamOrg = await my_teamOrg(params)
            console.log(teamOrg)
            if(teamOrg.count){
                this.teamOrg.count = teamOrg.count
    
                let start = 10 * (this.page - 1)
                teamOrg.res.forEach((im,ix) => {
                    im.ranking = start+ix + 1
                })
    
                this.teamOrg.data[this.page - 1] = teamOrg.res
                this.showTempData = false
                this.$forceUpdate()
            }else{
                this.showTempData = true
                console.log('11111111111111111')
            }
            console.log(this.teamOrg)
        },
        searchData(){
            this.page = 1
            this.initialBrfore()
        },
        currentChange(value){
            this.page = value
            this.initialBrfore()
            this.scrollTop()
        },
        currentChangeStudy(value){
            this.page1 = value
            this.getStudyPress()
        }
    },
    computed:{
        cominfor(){
            return this.$store.getters.cominfor
        },
        branch(){
            return this.my_team.branch
        },
    },
    watch:{
        branch(n){
			this.my_team.offices = ''
            /* console.log(n)
            if(n != '临床/医技科室'){
                console.log(123123)
                this.my_team.offices = ''
            }
            this.$forceUpdate() */
        },
        value1(){
            if(this.value1){
                this.my_team.start_time = this.value1[0]
                this.my_team.end_time = this.value1[1]
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.xunz{
    width: 80px;
    height: 20px;
    margin-left: 10px;
    background-size: cover!important;
    color: #fff;
    font-size: 10px;
    line-height: 27px;
    text-align: center;
}
.personInfo{
    height: 46px;
}
.redStudy{
    margin-left: 20px;
    margin-top: 50px;
    table{
        width: 850px;
        margin-top: 40px;
        tr{
            height: 82px;
        }
        th{
            font-size: 14px;
        }
        td{
            color: #333;
            font-size: 15px;
            border: 1px solid #e6e6e6;
        }
    }
}
button{
    font-size: 16px;
    color: #fff;
    padding: 11px 10px;
    background: #47D7E3;
    margin-left: 9px;
    cursor: pointer;
}
.member{
    .operList{
        margin-top: 20px;
        margin-left: 20px;
        text-align: left;
    }
}
</style>